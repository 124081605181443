import { useEffect, useReducer, useMemo } from 'react';
import update from 'immutability-helper';
import { navigate } from 'gatsby';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Container,
} from '@mui/material';
import { SitePage } from 'app/layout';
import { useAppDispatch, createProfile, useAppSelector } from 'app/store';
import { QuestionStepper } from 'app/survey/questions';
import { getProfileQuestions, IntroVideoTranscript } from 'app/profile/getProfileQuestions';

import type { state } from 'app';

import { ensureAuth } from 'app/helpers';
import { BasicVideo } from 'app/topics/pip';
import { nextSurveyInPathway } from 'app/survey/helpers';
import { ONBOARDING_VIDEO_URL } from 'app/profile/ProfileBlock';

type CreateProfileState = {
  step: number;
  user: Partial<state.User>;
};

type Action =
  | {
      type: 'set-step';
      payload: number;
    }
  | {
      type: 'update-user';
      payload: Partial<state.User>;
    };

const setStep = (step: number): Action => {
  return {
    type: 'set-step',
    payload: step,
  };
};

const updateUser = (data: Partial<state.User>): Action => {
  return {
    type: 'update-user',
    payload: data,
  };
};

const createProfileReducer = (state: CreateProfileState, action: Action) => {
  switch (action.type) {
    case 'set-step':
      return update(state, { step: { $set: action.payload } });

    case 'update-user':
      return update(state, { user: { $merge: action.payload } });

    default:
      return state;
  }
};

function CreateProfilePage({ onSubmit }: { onSubmit: (data: state.User) => void }) {
  const [state, dispatch] = useReducer(createProfileReducer, {
    step: 0,
    user: {
      currentStage: 'survey',
    },
  });
  const { step, user } = state;
  const questions = useMemo(() => {
    return getProfileQuestions({});
  }, [user]);

  useEffect(() => {
    if (step === questions.length) {
      onSubmit(state.user as state.User);
    }
  }, [step]);

  return (
    <SitePage>
      <Container>
        <Grid
          container
          alignItems="start"
          sx={{
            mt: 2,
          }}
        >
          <Grid item p={2} pb={0} xs={12}>
            <Typography variant="h4" gutterBottom>
              Welcome
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box px={2} pb={0}>
              <Typography variant='body1' paragraph>
                Welcome and congratulations on taking this step to support your young person’s mental wellbeing. Before
                you get started with your program, please take a few moments to watch this short video and complete your
                profile. The questions asked below are used to personalise your program and help us to understand who
                uses the program. Some questions must be answered, these are indicated with an asterisk (*). Other
                questions are optional. We would appreciate you answering these questions, however you can skip them if
                you prefer.
              </Typography>

              <Typography sx={{ mb: 2 }}>
                This short video will talk you through what to expect from the program, what you might get out of it,
                and show you how to navigate the online program. Watch the video or click to read the transcript.
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            px={4}
            display="flex"
            sx={{ flexDirection: 'column', alignItems: { xs: 'center', md: 'start' } }}
            justifyContent="center"
          >
            <BasicVideo sx={{ width: '100%', mt: 0, mb: 1 }} url={ONBOARDING_VIDEO_URL} />
            <IntroVideoTranscript />
          </Grid>

          <Grid item xs={12}>
            <QuestionStepper
              sections={questions}
              step={step}
              setStep={(step) => dispatch(setStep(step))}
              data={user}
              onChange={(data) => dispatch(updateUser(data))}
              finishLabel="Submit"
            />
          </Grid>
        </Grid>
      </Container>
    </SitePage>
  );
}

export function CreateProfile() {
  const dispatch = useAppDispatch();
  const profileData = useAppSelector((state) => state.profile.data);

  const handleSubmit = (data: state.User) => {
    dispatch(createProfile(data));
  };

  if (profileData !== null) {
    return (
      <Dialog open={true}>
        <DialogTitle>Thank you</DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            You have now completed your profile. Check out your personal dashboard for the next steps of your program.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              navigate(
                profileData.user.userPathway === 'Non-tailored'
                  ? '/dashboard#topic'
                  : `/surveys/${nextSurveyInPathway(profileData.user, undefined, 'baseline')}`,
                {
                  replace: true,
                },
              )
            }
          >
            Go
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return <CreateProfilePage onSubmit={handleSubmit} />;
}

export default ensureAuth(CreateProfile, false);
